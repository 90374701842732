<template>
  <div class="AppInfoList-wrapper">
    <list
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create" />
      </template>
      <template #searchSlot>
        <v-input label="APP唯一标识" v-model="searchParams.appCode" placeholder="请输入" />
        <v-input label="APP名称" v-model="searchParams.appName" placeholder="请输入" />
        <v-select label="APP类型" v-model="searchParams.appType" :options="appTypeOps" />
        <v-select2 label="操作人" v-model="searchParams.updateUser" v-bind="updateUserParams" />
        <v-datepicker label="最后操作时间" :maxDate="maxDate" type="rangedatetimer" :startTime.sync="searchParams.updateTimeFrom" :endTime.sync="searchParams.updateTimeTo" />
      </template>
      <template #operateSlot="scope">
        <v-button text="基础配置" type="text" permission="update" @click="edit(scope.row)" />
        <v-button text="升级配置" type="text" permission="update" @click="editUpdate(scope.row)" />
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, getUserListURL } from './api'
import { appTypeMap, setAppTypeOps } from './map'

export default {
  name: 'AppInfoList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      appTypeOps: setAppTypeOps(1),
      maxDate: new Date(),
      updateUserParams: {
        searchUrl: getUserListURL,
        request: {
          text: 'keyword',
          value: 'id'
        },
        response: {
          text: 'username',
          value: 'id'
        }
      },
      searchParams: {
        appCode: '',
        appName: '',
        appType: undefined,
        updateUser: '',
        updateTimeFrom: '',
        updateTimeTo: ''
      },
      headers: [
        {
          prop: 'appCode',
          label: 'APP唯一标识'
        },
        {
          prop: 'appName',
          label: 'APP名称'
        },
        {
          prop: 'appTypeText',
          label: 'APP类型',
          formatter: row => appTypeMap[row.appType]
        },
        {
          prop: 'appDownloadUrl',
          label: 'APP下载地址'
        },
        {
          prop: 'appDownloadUrlShort',
          label: '下载短地址'
        },
        {
          prop: 'updateUser',
          label: '操作人'
        },
        {
          prop: 'updateTime',
          label: '最后操作时间'
        }
      ]
    }
  },
  computed: {
    visible () {
      const userType = this.$store.state.userInfo.userType
      return userType === '106'
    }
  },
  methods: {
    create () {
      if (this.visible) {
        this.$router.push({
          name: 'appInfoForm'
        })
      } else {
        this.$message.error('用户无操作权限!')
      }
    },
    edit (row) {
      if (this.visible) {
        this.$router.push({
          name: 'appInfoForm',
          query: {
            id: row.id
          }
        })
      } else {
        this.$message.error('用户无操作权限!')
      }
    },
    editUpdate (row) {
      if (this.visible) {
        console.log('appName:', row.appName)
        this.$router.push({
          name: 'updateConfigForm',
          query: {
            id: row.id,
            appName: row.appName
          }
        })
      } else {
        this.$message.error('用户无操作权限!')
      }
    }
  }
}
</script>
