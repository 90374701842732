// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}appInfo/listAppManage`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}appInfo/list/export`
// 操作人 select2
const getUserListURL = `${API_CONFIG.butlerBaseURL}agreement/user/search/keyword`
// 获取更新tab信息
const getAppTabsInfoURL = `${API_CONFIG.butlerBaseURL}appInfo/manage/appVersion/view`
// 保存
const saveAppInfoURL = `${API_CONFIG.butlerBaseURL}appInfo/appVersionSaveSnapshot`
// 发布
const publishAppInfoURL = `${API_CONFIG.butlerBaseURL}appInfo/appVersionPublish`
// 新增 & 编辑
const updateURL = `${API_CONFIG.butlerBaseURL}appInfo/addOrUpdate`
// 获取详情
const queryDetailURL = `${API_CONFIG.butlerBaseURL}appInfo/manage/view`

export {
  getListURL,
  exportListURL,
  getUserListURL,
  updateURL,
  getAppTabsInfoURL,
  saveAppInfoURL,
  publishAppInfoURL,
  queryDetailURL
}
